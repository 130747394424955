exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-adas-js": () => import("./../../../src/pages/adas.js" /* webpackChunkName: "component---src-pages-adas-js" */),
  "component---src-pages-automation-and-validation-js": () => import("./../../../src/pages/automation-and-validation.js" /* webpackChunkName: "component---src-pages-automation-and-validation-js" */),
  "component---src-pages-automotive-js": () => import("./../../../src/pages/automotive.js" /* webpackChunkName: "component---src-pages-automotive-js" */),
  "component---src-pages-automotive-system-software-js": () => import("./../../../src/pages/automotive-system-software.js" /* webpackChunkName: "component---src-pages-automotive-system-software-js" */),
  "component---src-pages-autosar-classic-js": () => import("./../../../src/pages/autosar-classic.js" /* webpackChunkName: "component---src-pages-autosar-classic-js" */),
  "component---src-pages-autosar-js": () => import("./../../../src/pages/autosar.js" /* webpackChunkName: "component---src-pages-autosar-js" */),
  "component---src-pages-blog-details-js": () => import("./../../../src/pages/blog-details.js" /* webpackChunkName: "component---src-pages-blog-details-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-communication-protocols-js": () => import("./../../../src/pages/communication-protocols.js" /* webpackChunkName: "component---src-pages-communication-protocols-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-embedded-system-training-js": () => import("./../../../src/pages/embedded-system-training.js" /* webpackChunkName: "component---src-pages-embedded-system-training-js" */),
  "component---src-pages-engineering-services-js": () => import("./../../../src/pages/engineering-services.js" /* webpackChunkName: "component---src-pages-engineering-services-js" */),
  "component---src-pages-feature-details-js": () => import("./../../../src/pages/feature-details.js" /* webpackChunkName: "component---src-pages-feature-details-js" */),
  "component---src-pages-features-js": () => import("./../../../src/pages/features.js" /* webpackChunkName: "component---src-pages-features-js" */),
  "component---src-pages-feedback-js": () => import("./../../../src/pages/feedback.js" /* webpackChunkName: "component---src-pages-feedback-js" */),
  "component---src-pages-functional-safety-fusa-js": () => import("./../../../src/pages/functional-safety-fusa.js" /* webpackChunkName: "component---src-pages-functional-safety-fusa-js" */),
  "component---src-pages-functional-safety-js": () => import("./../../../src/pages/functional-safety.js" /* webpackChunkName: "component---src-pages-functional-safety-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-iot-js": () => import("./../../../src/pages/iot.js" /* webpackChunkName: "component---src-pages-iot-js" */),
  "component---src-pages-product-details-js": () => import("./../../../src/pages/product-details.js" /* webpackChunkName: "component---src-pages-product-details-js" */),
  "component---src-pages-project-details-js": () => import("./../../../src/pages/project-details.js" /* webpackChunkName: "component---src-pages-project-details-js" */),
  "component---src-pages-projects-1-js": () => import("./../../../src/pages/projects-1.js" /* webpackChunkName: "component---src-pages-projects-1-js" */),
  "component---src-pages-projects-2-js": () => import("./../../../src/pages/projects-2.js" /* webpackChunkName: "component---src-pages-projects-2-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-service-details-js": () => import("./../../../src/pages/service-details.js" /* webpackChunkName: "component---src-pages-service-details-js" */),
  "component---src-pages-services-1-js": () => import("./../../../src/pages/services-1.js" /* webpackChunkName: "component---src-pages-services-1-js" */),
  "component---src-pages-services-2-js": () => import("./../../../src/pages/services-2.js" /* webpackChunkName: "component---src-pages-services-2-js" */),
  "component---src-pages-services-3-js": () => import("./../../../src/pages/services-3.js" /* webpackChunkName: "component---src-pages-services-3-js" */),
  "component---src-pages-services-4-js": () => import("./../../../src/pages/services-4.js" /* webpackChunkName: "component---src-pages-services-4-js" */),
  "component---src-pages-services-5-js": () => import("./../../../src/pages/services-5.js" /* webpackChunkName: "component---src-pages-services-5-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-timely-delivery-and-innovative-approach-js": () => import("./../../../src/pages/timely-delivery-and-innovative-approach.js" /* webpackChunkName: "component---src-pages-timely-delivery-and-innovative-approach-js" */),
  "component---src-pages-uds-and-obd-js": () => import("./../../../src/pages/uds-and-obd.js" /* webpackChunkName: "component---src-pages-uds-and-obd-js" */),
  "component---src-pages-validation-and-verification-js": () => import("./../../../src/pages/validation-and-verification.js" /* webpackChunkName: "component---src-pages-validation-and-verification-js" */)
}

